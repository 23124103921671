input,
.ant-select-selection-item {
    font-weight: 600 !important;
}

.ant-form .ant-form-item .ant-form-item-label,
.ant-form .ant-form-item .ant-form-item-control {
    flex: none !important;
}

.ant-form-item-label > label,
.label-text {
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 3px !important;
}

.label-text {
    display: inline-block;
    font-size: 12px;
    margin-bottom: 6px !important;
}

.ant-input {
    font-weight: 600;
    height: 36px;
    border-width: 2px;
}

body .ant-input-affix-wrapper {
    border-radius: 6px !important;
    overflow: hidden;

    .ant-input {
        border: none !important;
        height: auto;
    }
}

.fw-placeholder-600 {
  &::placeholder {
      font-weight: 600 !important;
  }
}

.ant-form-item {
    @include media("<tablet") {
        margin-bottom: 5px;
    }
}

.ant-form-item-with-help .ant-form-item-explain {
    font-size: 12px;
    line-height: 2;
    margin-left: 3px;
    text-align: left;
}

body .ant-input-search {
    .ant-input-group-addon {
        display: none;
    }

    .ant-input {
        border-color: transparent !important;
    }
}

.filter-search {
    .anticon-search {
        color: $primary-color;
    }

    .anticon-close-circle {
        &:hover {
            color: lighten($grey, 20%);
        }
    }
}

.ant-input-search,
.ant-form-item {
    .ant-input-prefix {
        font-size: 20px;
        margin-right: 8px;
    }

    &.filter-search {
        .table-action-bar & {
            width: 100% !important;

            @include media(">=tablet") {
                width: 400px !important;
            }
        }

        .ant-modal & {
            margin-bottom: 10px;
        }
    }
}

.ant-checkbox-wrapper {
    font-size: 12px;
    text-transform: uppercase;
}

.ant-checkbox {
    top: 0.3em;
}

.radio-dark {
    .ant-radio-wrapper {
        .ant-radio-inner {
            border-color: $text-color;
            border-width: 1px;
        }

        &:hover {
            .ant-radio-inner {
                border-color: $text-color;
            }
        }

        .ant-radio {
            &.ant-radio-checked {
                .ant-radio-inner {
                    border-width: 2px;
                }
            }
        }
    }
}

.ant-radio-wrapper {
    transition: color 0.2s ease-in-out;

    .ant-radio {
        .ant-radio-input {
            &:focus + .ant-radio-inner {
                box-shadow: none;
            }
        }
    }

    .ant-radio-inner {
        border-color: $lighten-gray;
    }
}

.radio-main {
    padding: 11px 7px 0;
}

.radio-modal {
    .ant-radio-wrapper {
        font-size: 14px !important;

        &:hover {
            color: inherit;

            .ant-radio-inner {
                border-color: inherit;
            }
        }
    }
}

.ant-select,
.ant-select-item,
.ant-select-selection-item {
    text-align: left;
    font-weight: 600;
}

.ant-select-selector,
.select-model-modal-button {
    border-width: 2px !important;
}

.ant-select-arrow {
    color: inherit;

    .anticon {
        font-size: 22px;
    }
}

.ant-input-password-icon.anticon {
    font-size: 18px;
    color: inherit;
}

.ant-switch {
    background-image: linear-gradient(to right, #f34246, #f34246);
}

.ant-switch-checked {
    background: $green-2 !important;
}

.ant-picker-time-panel-cell-inner {
    color: $charleston-green !important;
}

.ant-picker {
    width: 100%;
}

.ant-select-item-option-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
    cursor: not-allowed;
}
